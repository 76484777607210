/**
 * Clearing Platform API
 * The Clearing Platform API (for Partner Access) provides a standardized client interface to a Clearing Platform Systems for creating and tracking clearing tickets. It also contains access to metadata like ItuCarrier  and supports attachments (store and retrieve)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ClearingTicketCreateEvent } from '../model/clearingTicketCreateEvent';
import { ClearingTicketDataChangeEvent } from '../model/clearingTicketDataChangeEvent';
import { ClearingTicketNoteAddEvent } from '../model/clearingTicketNoteAddEvent';
import { ClearingTicketResolvedEvent } from '../model/clearingTicketResolvedEvent';
import { ClearingTicketSeverityChangeEvent } from '../model/clearingTicketSeverityChangeEvent';
import { ClearingTicketStatusChangeEvent } from '../model/clearingTicketStatusChangeEvent';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class NotificationListenerService {

    protected basePath = 'https://serverRoot/partner-api/v1/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Client listener for entity ClearingTicketCreateEvent
     * Client listener for receiving the notification ClearingTicketStatusChangeEvent
     * @param body The event data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listenToCreateEvent(body: ClearingTicketCreateEvent, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listenToCreateEvent(body: ClearingTicketCreateEvent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listenToCreateEvent(body: ClearingTicketCreateEvent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listenToCreateEvent(body: ClearingTicketCreateEvent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listenToCreateEvent.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/listener/troubleTicketCreateEvent`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Client listener for entity ClearingTicketDataChangeEvent
     * Client listener for receiving the notification ClearingTicketDataChangeEvent
     * @param body The event data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listenToDataChangeEvent(body: ClearingTicketDataChangeEvent, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listenToDataChangeEvent(body: ClearingTicketDataChangeEvent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listenToDataChangeEvent(body: ClearingTicketDataChangeEvent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listenToDataChangeEvent(body: ClearingTicketDataChangeEvent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listenToDataChangeEvent.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/listener/troubleTicketDataChangeEvent`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Client listener for entity ClearingTicketNoteAddEvent
     * Listener for receiving the notification troubleTicketNoteAddEvent
     * @param body The event data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listenToNoteAddEvent(body: ClearingTicketNoteAddEvent, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listenToNoteAddEvent(body: ClearingTicketNoteAddEvent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listenToNoteAddEvent(body: ClearingTicketNoteAddEvent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listenToNoteAddEvent(body: ClearingTicketNoteAddEvent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listenToNoteAddEvent.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/listener/troubleTicketNoteAddEvent`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Client listener for entity ClearingTicketResolvedEvent
     * Client listener for receiving the notification ClearingTicketResolvedEvent
     * @param body The event data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listenToResolvedEvent(body: ClearingTicketResolvedEvent, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listenToResolvedEvent(body: ClearingTicketResolvedEvent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listenToResolvedEvent(body: ClearingTicketResolvedEvent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listenToResolvedEvent(body: ClearingTicketResolvedEvent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listenToResolvedEvent.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/listener/troubleTicketResolvedEvent`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Client listener for entity ClearingTicketSeverityhangeEvent
     * Client listener for receiving the notification ClearingTicketSeverityChangeEvent
     * @param body The event data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listenToSeverityChangeEvent(body: ClearingTicketSeverityChangeEvent, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listenToSeverityChangeEvent(body: ClearingTicketSeverityChangeEvent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listenToSeverityChangeEvent(body: ClearingTicketSeverityChangeEvent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listenToSeverityChangeEvent(body: ClearingTicketSeverityChangeEvent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listenToSeverityChangeEvent.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/listener/troubleTicketSeverityChangeEvent`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Client listener for entity ClearingTicketStatusChangeEvent
     * Client listener for receiving the notification ClearingTicketStatusChangeEvent
     * @param body The event data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listenToStatusChangeEvent(body: ClearingTicketStatusChangeEvent, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listenToStatusChangeEvent(body: ClearingTicketStatusChangeEvent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listenToStatusChangeEvent(body: ClearingTicketStatusChangeEvent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listenToStatusChangeEvent(body: ClearingTicketStatusChangeEvent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listenToStatusChangeEvent.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/listener/troubleTicketStatusChangeEvent`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
