/**
 * Clearing Platform API
 * The Clearing Platform API (for Partner Access) provides a standardized client interface to a Clearing Platform Systems for creating and tracking clearing tickets. It also contains access to metadata like ItuCarrier  and supports attachments (store and retrieve)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Attachment } from '../model/attachment';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AttachmentService {

    protected basePath = 'https://serverRoot/partner-api/v1/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create an attachment
     * This operation creates an attachment on the platform and returns a relative url with a unique id.
     * @param contentType
     * @param body The attachment
     * @param filename
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAttachment(contentType: string, body?: Object, filename?: string, observe?: 'body', reportProgress?: boolean): Observable<Attachment>;
    public createAttachment(contentType: string, body?: Object, filename?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Attachment>>;
    public createAttachment(contentType: string, body?: Object, filename?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Attachment>>;
    public createAttachment(contentType: string, body?: Object, filename?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contentType === null || contentType === undefined) {
            throw new Error('Required parameter contentType was null or undefined when calling createAttachment.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filename !== undefined && filename !== null) {
            queryParameters = queryParameters.set('filename', <any>filename);
        }

        let headers = this.defaultHeaders;
        if (contentType !== undefined && contentType !== null) {
            headers = headers.set('Content-Type', String(contentType));
        }

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        //TODO:check this part of the code, it overrides content-type headers. Currently commented out.

        // // to determine the Content-Type header
        // const consumes: string[] = [
        //     '*/*'
        // ];
        // const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        // if (httpContentTypeSelected != undefined) {
        //     headers = headers.set('Content-Type', httpContentTypeSelected);
        // }

        return this.httpClient.request<Attachment>('post',`${this.basePath}/attachment`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves an attachment by ID
     * This operation retrieves an attachment by ID.
     * @param id Identifier of the attachment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveAttachment(id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public retrieveAttachment(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public retrieveAttachment(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public retrieveAttachment(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling retrieveAttachment.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream',
            'application/json;charset=utf-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request('get',`${this.basePath}/attachment/${encodeURIComponent(String(id))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
