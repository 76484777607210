/**
 * Clearing Platform API
 * The Clearing Platform API (for Partner Access) provides a standardized client interface to a Clearing Platform Systems for creating and tracking clearing tickets. It also contains access to metadata like ItuCarrier  and supports attachments (store and retrieve)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ClearingData } from '../model/clearingData';
import { ClearingTicket } from '../model/clearingTicket';
import { Note } from '../model/note';
import { Severity } from '../model/severity';
import { SeverityType } from '../model/severityType';
import { StatusChange } from '../model/statusChange';
import { StatusChangeResolved } from '../model/statusChangeResolved';
import { StatusType } from '../model/statusType';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ClearingTicketService {

    protected basePath = 'https://serverRoot/partner-api/v1/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Adds a new ClearingTicket note
     * This operation adds a new ClearingTicket note.
     * @param body The ClearingTicket note to be added
     * @param id Identifier of the ClearingTicket
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addClearingTicketNote(body: Note, id: string, observe?: 'body', reportProgress?: boolean): Observable<ClearingTicket>;
    public addClearingTicketNote(body: Note, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClearingTicket>>;
    public addClearingTicketNote(body: Note, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClearingTicket>>;
    public addClearingTicketNote(body: Note, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addClearingTicketNote.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling addClearingTicketNote.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClearingTicket>('post',`${this.basePath}/troubleTicket/${encodeURIComponent(String(id))}/note`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a ClearingTicket
     * This operation creates a ClearingTicket entity.
     * @param body The ClearingTicket to be created
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createClearingTicket(body: ClearingTicket, observe?: 'body', reportProgress?: boolean): Observable<ClearingTicket>;
    public createClearingTicket(body: ClearingTicket, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClearingTicket>>;
    public createClearingTicket(body: ClearingTicket, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClearingTicket>>;
    public createClearingTicket(body: ClearingTicket, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createClearingTicket.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClearingTicket>('post',`${this.basePath}/troubleTicket`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lists or finds ClearingTicket objects
     * This operation lists ClearingTicket entities
     * @param externalId Select only clearing tickets with externalId equal &#x27;externalId&#x27;. An empty parameter is ignored.
     * @param ticketType Select only clearing tickets with scenarios &#x3D; &#x27;ticketType&#x27; (allows pattern: e.g. 1.* selects all scenarios 1.0 — 1.9). An empty parameter is ignored.
     * @param status Select only clearing tickets with status equal &#x27;status&#x27;. An empty parameter is ignored.
     * @param originator Select only clearing tickets with originator equal &#x27;originator&#x27;. An empty parameter is ignored.
     * @param processor Select only clearing tickets with processor equal &#x27;processor&#x27;. An empty parameter is ignored.
     * @param severity Select only clearing tickets with severity equal &#x27;severity&#x27;. An empty parameter is ignored.
     * @param requestedResolutionDateFrom Select only clearing tickets with requestedResolutionDate higher &#x27;requestedResolutionDate&#x27;. An empty parameter is ignored.
     * @param requestedResolutionDateTo Select only clearing tickets with requestedResolutionDate less equal &#x27;requestedResolutionDate&#x27;. An empty parameter is ignored.
     * @param creationDateFrom Select only clearing tickets with creation date equal or higher &#x27;creationDateFrom&#x27; An empty parameter is ignored.
     * @param creationDateTo Select only clearing tickets with creation date less equal &#x27;creationDateTo&#x27; An empty parameter is ignored.
     * @param lastUpdateFrom Select only clearing tickets with last update equal or higher &#x27;lastUpdateFrom&#x27;. An empty parameter is ignored.
     * @param lastUpdateTo Select only clearing tickets with last update less equal &#x27;lastUpdateTo&#x27;. An empty parameter is ignored.
     * @param offset Requested index for start of selected clearing tickets to be provided in response. Note: Sort order is always by creation date.
     * @param limit Requested number of selected clearing tickets to be provided in response. Note: Sort order is always by creation date.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listClearingTicket(externalId?: string, ticketType?: string, status?: StatusType, originator?: string, processor?: string, severity?: SeverityType, requestedResolutionDateFrom?: string, requestedResolutionDateTo?: string, creationDateFrom?: string, creationDateTo?: string, lastUpdateFrom?: Date, lastUpdateTo?: Date, offset?: number, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ClearingTicket>>;
    public listClearingTicket(externalId?: string, ticketType?: string, status?: StatusType, originator?: string, processor?: string, severity?: SeverityType, requestedResolutionDateFrom?: string, requestedResolutionDateTo?: string, creationDateFrom?: string, creationDateTo?: string, lastUpdateFrom?: Date, lastUpdateTo?: Date, offset?: number, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClearingTicket>>>;
    public listClearingTicket(externalId?: string, ticketType?: string, status?: StatusType, originator?: string, processor?: string, severity?: SeverityType, requestedResolutionDateFrom?: string, requestedResolutionDateTo?: string, creationDateFrom?: string, creationDateTo?: string, lastUpdateFrom?: Date, lastUpdateTo?: Date, offset?: number, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClearingTicket>>>;
    public listClearingTicket(externalId?: string, ticketType?: string, status?: StatusType, originator?: string, processor?: string, severity?: SeverityType, requestedResolutionDateFrom?: string, requestedResolutionDateTo?: string, creationDateFrom?: string, creationDateTo?: string, lastUpdateFrom?: Date, lastUpdateTo?: Date, offset?: number, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (externalId !== undefined && externalId !== null) {
            queryParameters = queryParameters.set('externalId', <any>externalId);
        }
        if (ticketType !== undefined && ticketType !== null) {
            queryParameters = queryParameters.set('ticketType', <any>ticketType);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (originator !== undefined && originator !== null) {
            queryParameters = queryParameters.set('originator', <any>originator);
        }
        if (processor !== undefined && processor !== null) {
            queryParameters = queryParameters.set('processor', <any>processor);
        }
        if (severity !== undefined && severity !== null) {
            queryParameters = queryParameters.set('severity', <any>severity);
        }
        if (requestedResolutionDateFrom !== undefined && requestedResolutionDateFrom !== null) {
            queryParameters = queryParameters.set('requestedResolutionDateFrom', <any>requestedResolutionDateFrom);
        }
        if (requestedResolutionDateTo !== undefined && requestedResolutionDateTo !== null) {
            queryParameters = queryParameters.set('requestedResolutionDateTo', <any>requestedResolutionDateTo);
        }
        if (creationDateFrom !== undefined && creationDateFrom !== null) {
            queryParameters = queryParameters.set('creationDateFrom', <any>creationDateFrom);
        }
        if (creationDateTo !== undefined && creationDateTo !== null) {
            queryParameters = queryParameters.set('creationDateTo', <any>creationDateTo);
        }
        if (lastUpdateFrom !== undefined && lastUpdateFrom !== null) {
            queryParameters = queryParameters.set('lastUpdateFrom', <any>lastUpdateFrom.toISOString());
        }
        if (lastUpdateTo !== undefined && lastUpdateTo !== null) {
            queryParameters = queryParameters.set('lastUpdateTo', <any>lastUpdateTo.toISOString());
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ClearingTicket>>('get',`${this.basePath}/troubleTicket`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a ClearingTicket by ID
     * This operation retrieves a ClearingTicket entity.
     * @param id Identifier of the ClearingTicket
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveClearingTicket(id: string, observe?: 'body', reportProgress?: boolean): Observable<ClearingTicket>;
    public retrieveClearingTicket(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClearingTicket>>;
    public retrieveClearingTicket(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClearingTicket>>;
    public retrieveClearingTicket(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling retrieveClearingTicket.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ClearingTicket>('get',`${this.basePath}/troubleTicket/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set new ClearingTicket data
     * This operation sets new ClearingTicket data. Only allowed at status \&quot;pending\&quot;.
     * @param body The ClearingData to be set
     * @param id Identifier of the ClearingTicket
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setClearingTicketData(body: ClearingData, id: string, observe?: 'body', reportProgress?: boolean): Observable<ClearingTicket>;
    public setClearingTicketData(body: ClearingData, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClearingTicket>>;
    public setClearingTicketData(body: ClearingData, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClearingTicket>>;
    public setClearingTicketData(body: ClearingData, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setClearingTicketData.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setClearingTicketData.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClearingTicket>('patch',`${this.basePath}/troubleTicket/${encodeURIComponent(String(id))}/clearingData`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Report a ClearingTicket resolution
     * This operation sets a ClearingTicket to status resolved.
     * @param body The data to be set along with the resolved status
     * @param id Identifier of the ClearingTicket
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setClearingTicketResolved(body: StatusChangeResolved, id: string, observe?: 'body', reportProgress?: boolean): Observable<ClearingTicket>;
    public setClearingTicketResolved(body: StatusChangeResolved, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClearingTicket>>;
    public setClearingTicketResolved(body: StatusChangeResolved, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClearingTicket>>;
    public setClearingTicketResolved(body: StatusChangeResolved, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setClearingTicketResolved.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setClearingTicketResolved.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClearingTicket>('patch',`${this.basePath}/troubleTicket/${encodeURIComponent(String(id))}/resolved`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set a new ClearingTicket severity
     * This operation sets a new ClearingTicket severity.
     * @param body The new severity to be set
     * @param id Identifier of the ClearingTicket
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setClearingTicketSeverity(body: Severity, id: string, observe?: 'body', reportProgress?: boolean): Observable<ClearingTicket>;
    public setClearingTicketSeverity(body: Severity, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClearingTicket>>;
    public setClearingTicketSeverity(body: Severity, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClearingTicket>>;
    public setClearingTicketSeverity(body: Severity, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setClearingTicketSeverity.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setClearingTicketSeverity.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClearingTicket>('patch',`${this.basePath}/troubleTicket/${encodeURIComponent(String(id))}/severity`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a ClearingTicket status change
     * This operation requests a ClearingTicket status change.
     * @param body The new ClearingTicket status to be set
     * @param id Identifier of the ClearingTicket
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setClearingTicketStatus(body: StatusChange, id: string, observe?: 'body', reportProgress?: boolean): Observable<ClearingTicket>;
    public setClearingTicketStatus(body: StatusChange, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClearingTicket>>;
    public setClearingTicketStatus(body: StatusChange, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClearingTicket>>;
    public setClearingTicketStatus(body: StatusChange, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setClearingTicketStatus.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setClearingTicketStatus.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;charset=utf-8'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClearingTicket>('patch',`${this.basePath}/troubleTicket/${encodeURIComponent(String(id))}/status`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
