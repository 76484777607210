/**
 * Clearing Platform API
 * The Clearing Platform API (for Partner Access) provides a standardized client interface to a Clearing Platform Systems for creating and tracking clearing tickets. It also contains access to metadata like ItuCarrier  and supports attachments (store and retrieve)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * The current status of the clearing ticket. Status is one of the following.
 */
export type StatusType =
  'acknowledged'
  | 'pending'
  | 'held'
  | 'inProgress'
  | 'cancelled'
  | 'closed'
  | 'resolved'
  | 'final';

export const StatusType = {
  Acknowledged: 'acknowledged' as StatusType,
  Pending: 'pending' as StatusType,
  Held: 'held' as StatusType,
  InProgress: 'inProgress' as StatusType,
  Cancelled: 'cancelled' as StatusType,
  Closed: 'closed' as StatusType,
  Resolved: 'resolved' as StatusType,
  Final: 'final' as StatusType
};
